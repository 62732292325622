import React from 'react';
import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import styles from './save-modal.css';
import Box from '../box/box.jsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { closeSavePopModal } from '../../reducers/modals';
import closeIcon from './close_icon.png';

import { saveMarsProject } from '../../reducers/project-state';

class SaveModal extends React.Component {
  constructor(props) {
    super(props);
}

handleCloseClick() {
  this.props.onCloseSavePop();
}

handleItemClick() {
  var iptClassName = classNames(styles.titleInput);
  var textareaClassName = classNames(styles.containText);
  var inputEl = document.getElementsByClassName(iptClassName)[0];
  var itextareaEl = document.getElementsByClassName(textareaClassName)[0];
  this.props.onSaveProject(null, inputEl.value, itextareaEl.value);
  this.props.onCloseSavePop();
}

  render() {

    return (
      <Box>
        <div className={classNames(styles.saveModalWrapper)}>
          <div className={classNames(styles.saveModal)}>
            <div className={classNames(styles.saveModalTitle)}>另存为作品<span className={classNames(styles.closeIconBox)} onClick={() => {this.handleCloseClick()}}><img className={classNames(styles.closeIcon)} src={closeIcon} /></span></div>
            <div className={classNames(styles.saveModalContent)}>
              <dl className={classNames(styles.saveModalContentBox)}>
                <dt className={classNames(styles.saveModalContentBoxTitle)}>
                  <span className={classNames(styles.titleName)}><i className={classNames(styles.titleImportant)}>*</i>作品名称：</span>
                  <input className={classNames(styles.titleInput)} type="text"/>
                </dt>
                <dd className={classNames(styles.saveModalContentBoxContain)}>
                  <span className={classNames(styles.containName)}><i className={classNames(styles.containImportant)}>*</i>作品描述：</span>
                  <textarea className={classNames(styles.containText)}></textarea>
                </dd>
              </dl>
            </div>
            <div className={classNames(styles.saveModalOperator)}>
              <span
                className={classNames(styles.saveModalOperatorClose)}
                onClick={this.props.onCloseSavePop}
              >取消</span>
              <span className={classNames(styles.saveModalOperatorConfirm)} onClick={() => {this.handleItemClick()}}>确认</span>
            </div>
          </div>
          <div className={classNames(styles.mask)}></div>
        </div>
      </Box>
    )
  }
};

SaveModal.propTypes = {
  onCloseSavePop: PropTypes.func,
  onSaveProject: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  onCloseSavePop: () => dispatch(closeSavePopModal()),
  onSaveProject: (id, title, summary) => dispatch(saveMarsProject(id, title, summary))
});

export default compose(
  connect(
      ()=>({}),
      mapDispatchToProps
  )
)(SaveModal);