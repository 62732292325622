import xhr from 'xhr';

// const projectHost = 'http://marsapi.xueqiusoft.com/mars-box/scratch';  // 测试服
const projectHost = 'https://api.imarsclub.com/mars-box/scratch';  // 正式服

/**
 * Parsed cookie.
 * @return {object} The cookie object.
 */
const _marsCookie = () => {
    const cookies = document.cookie.split(';');
    return cookies.reduce((pre, next) => {
        const pair = next.split('=');
        if (pair.length === 2) {
            const key = pair[0].trim();
            const val = pair[1].trim();
            pre[key] = val;
        }
        return pre;
    }, {});
};

/**
 * Delete cookie.
 * @param {key} key of the cookie item to delete.
 */
const _delCookie = key => {
    document.cookie = `${key}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; domain=.imarsbox.com; path=/`;
}

/**
 * Get the usertoken form cookie.
 * @return {string} The usertoken.
 */
const userToken = () => {
    return _marsCookie().token;
};

/**
 * Get default project passded by the cookie.
 * Example: proj=''
 * @return {object} The project.
 */
const defaultProject = () => {
    let proj = null;
    const strProj = _marsCookie().proj;
    if (strProj && strProj.length > 0) {
        proj = JSON.parse(strProj);
        _delCookie('proj');
    }
    return proj;
};

/**
 * Save sb3 to the project server.
 * @param {object} content the sb3 blob.
 * @param {string} title the title of the project.
 * @param {number?} id the ID of the project, null if a new project.
 * @param {string?} summary the project summary.
 * @return {Promise} A promise that resolves when the network request resolves.
 */
const saveProject = (content, title, id, summary) => {
    const creatingProject = id === null || typeof id === 'undefined';

    const fd = new FormData();
    fd.append('file', content);

    if (!creatingProject) {
        fd.append('id', id);
    }

    if (title && title.length > 0) {
        fd.append('name', title);
    }

    if (summary && summary.length > 0) {
        fd.append('summary', summary);
    }

    const opts = {
        method: 'POST',
        url: `${projectHost}/production`,
        headers: {
            'X-HXR-MARSBOX-TOKEN': userToken()
        },
        body: fd
    };

    return new Promise((resolve, reject) => {
        xhr(opts, (err, response) => {
            if (err) return reject(err);
            resolve(JSON.parse(response.body).data);
        });
    });
};

/**
 * Save thumbnail of the project.
 * @param {number} id the ID of the project, null if a new project.
 * @param {object} content the thumbnail blob.
 * @return {Promise} A promise that resolves when the network request resolves.
 */
const saveProjectThumb = (id, content) => {
    const fd = new FormData();
    fd.append('file', content);
    fd.append('is_save', 1);
    fd.append('id', id);

    const opts = {
        method: 'POST',
        url: `${projectHost}/production/image`,
        headers: {
            'X-HXR-MARSBOX-TOKEN': userToken()
        },
        body: fd
    };

    return new Promise((resolve, reject) => {
        xhr(opts, err => (err ? reject() : resolve()));
    });
};

/**
 * Get the project list.
 * @return {Promise} A promise that resolves when the network request resolves.
 */
const getAllProject = () => {
    const opts = {
        method: 'GET',
        url: `${projectHost}/production`,
        headers: {
            'X-HXR-MARSBOX-TOKEN': userToken()
        }
    };

    return new Promise((resolve, reject) => {
        xhr(opts, (err, response) => {
            if (err) return reject(err);
            resolve(JSON.parse(response.body).data);
        });
    });
};

/**
 * Delete project from mars server.
 * @param {number} id the project id to delete.
 * @return {Promise} A promise that resolves when the network request resolves.
 */
const deleteProject = id => {
    const opts = {
        method: 'DELETE',
        url: `${projectHost}/production?id=${id}`,
        headers: {
            'X-HXR-MARSBOX-TOKEN': userToken()
        }
    };

    return new Promise((resolve, reject) => {
        xhr(opts, (err, response) => {
            if (err || response.statusCode !== 200) {
                return reject(err || new Error('Could not find project'));
            }

            resolve();
        });
    });
};

/**
 * Fetch sb3 content from the project server.
 * @param {string} uri the sb3 file path.
 * @return {Promise} A promise that resolves when the network request resolves.
 */
const fetchProject = uri => new Promise((resolve, reject) => {
    xhr({
        uri: `${uri}?${new Date().getTime()}`,
        responseType: 'arraybuffer'
    }, (err, response) => {
        if (err || response.statusCode !== 200) {
            return reject(err || new Error('Could not find project'));
        }

        resolve(response.body);
    });
});

export {
    userToken,
    defaultProject,
    saveProject,
    saveProjectThumb,
    fetchProject,
    getAllProject,
    deleteProject
};
