exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /* layout contants from `layout-constants.js` */ html {\n  font-size: 12px!important;\n} .tag-button_tag-button_nBLRM {\n    padding: .625rem 1rem;\n    background: hsla(215, 100%, 65%, 1);\n    border-radius: 1.375rem;\n    color: hsla(0, 100%, 100%, 1);\n    height: 2.5rem;\n} .tag-button_tag-button-icon_26hvC {\n    max-width: 1rem;\n    max-height: 1rem;\n} .tag-button_active_2stEz {\n    background: hsla(30, 100%, 55%, 1);\n}\n", ""]);

// exports
exports.locals = {
	"tag-button": "tag-button_tag-button_nBLRM",
	"tagButton": "tag-button_tag-button_nBLRM",
	"tag-button-icon": "tag-button_tag-button-icon_26hvC",
	"tagButtonIcon": "tag-button_tag-button-icon_26hvC",
	"active": "tag-button_active_2stEz"
};