exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\nhtml {\n  font-size: 12px!important;\n}\n\n.target-pane_target-pane_3S5E6 {\n    /* Makes columns for the sprite library selector + and the stage selector */\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: horizontal;\n    -webkit-box-direction: normal;\n    -webkit-flex-direction: row;\n        -ms-flex-direction: row;\n            flex-direction: row;\n    -webkit-box-flex: 1;\n    -webkit-flex-grow: 1;\n        -ms-flex-positive: 1;\n            flex-grow: 1;\n}\n\n.target-pane_stage-selector-wrapper_qekSW {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-flex-basis: 72px;\n        -ms-flex-preferred-size: 72px;\n            flex-basis: 72px;\n    -webkit-flex-shrink: 0;\n        -ms-flex-negative: 0;\n            flex-shrink: 0;\n}\n\n[dir=\"ltr\"] .target-pane_stage-selector-wrapper_qekSW {\n    margin-left: calc(0.5rem / 2);\n}\n\n[dir=\"rtl\"] .target-pane_stage-selector-wrapper_qekSW {\n    margin-right: calc(0.5rem / 2);\n}\n", ""]);

// exports
exports.locals = {
	"target-pane": "target-pane_target-pane_3S5E6",
	"targetPane": "target-pane_target-pane_3S5E6",
	"stage-selector-wrapper": "target-pane_stage-selector-wrapper_qekSW",
	"stageSelectorWrapper": "target-pane_stage-selector-wrapper_qekSW"
};